import {
  ASSESSMENT_TYPES,
  DEFAULT_INSTRUCTIONS
} from "pages/ManagementPage/Constants";
import mapTakerFields from "utils/helpers/mapTakerFields";

const mapInstructions = instructions => {
  const isNoWrappedList =
    instructions.includes("<li>") &&
    !(instructions.includes("<ol>") || instructions.includes("<ul>"));
  if (isNoWrappedList) return `<ol>${instructions}<ol>`;
  else return instructions;
};
export const getAssessmentCreationSchema = (
  assessmentData,
  assessmentSettings,
  getSectionsSchema,
  getTakerFieldsSchema,
  getCategoriesWeightSchema,
  getAttachedPools,
  getTimeToExpiration,
  tenantUser,
  isEditing,
  shouldRemoveAttempts
) => {
  const tenant = { id: tenantUser.id, name: tenantUser.value };
  const {
    instructions,
    showCustomCompletionPage,
    completionBody,
    completionButtonText,
    completionButtonUrl,
    completionHeading
  } = assessmentData;

  const schema = {
    name: assessmentData.title,
    slug: assessmentSettings.assessmentSlug,
    assessment_detail_id: 4,
    sections: getSectionsSchema(),
    tenant_id: tenant.id,
    tenant,
    is_public: assessmentSettings.selectedType === ASSESSMENT_TYPES.PUBLIC,
    taker_fields: getTakerFieldsSchema(),
    assessment_detail: {
      assessment_detail_content: {
        instructions: instructions,
        show_custom_completion_page: showCustomCompletionPage,
        completion_body: completionBody,
        completion_button_text: completionButtonText,
        completion_button_url: completionButtonUrl,
        completion_heading: completionHeading,
        language_id: 1
      }
    },
    ...(isEditing && {
      email_invite_template_id: assessmentSettings.email_invite_template_id
    }),
    ...(assessmentSettings.isQuestionPoolEnabled && {
      question_pool: getAttachedPools()
    }),
    email_sender: assessmentSettings.emailSender,
    email_sender_name: assessmentSettings.emailSenderName,
    email_to_notify: assessmentSettings.notificationSender,
    category_weights: getCategoriesWeightSchema(),
    language_id: 1,
    remove_attempts: shouldRemoveAttempts,
    attempt_limit: assessmentSettings.attemptLimit || null,
    attempt_limit_uom: assessmentSettings.attemptLimitUom || null,
    ...getTimeToExpiration(),
    open_date: assessmentSettings.openDate,
    close_date: assessmentSettings.closeDate,
    expiration_type: assessmentSettings.expirationType,
    webhooks: assessmentSettings.webhooks
  };

  if (!assessmentData.instructions) {
    schema.assessment_detail.assessment_detail_content.instructions = DEFAULT_INSTRUCTIONS;
  }

  return schema;
};

export const fillAssessmentData = async (
  assessmentData,
  setAssessmentData,
  setAssessmentSettings,
  setFetchedWeighting,
  setSectionsData,
  setIsEditing,
  setAssessmentId,
  categories,
  mapCategoriesWeight,
  getSections,
  getExpirationData,
  ASSESSMENT_TYPES,
  getQuestionsListMW
) => {
  if (!categories) return;

  const {
    id,
    name,
    assessment_detail: {
      assessment_detail_content: {
        instructions,
        completion_body,
        completion_button_text,
        completion_button_url,
        completion_heading,
        show_custom_completion_page
      }
    },
    taker_fields,
    slug,
    is_public,
    time_to_expiration,
    expires_on,
    email_invite_template_id,
    email_sender,
    email_sender_name,
    email_to_notify,
    category_weights,
    sections,
    configuration: { default_theme_color, logo_url },
    question_pool,
    attempt_limit,
    attempt_limit_uom,
    open_date,
    close_date,
    expiration_type,
    webhooks
  } = assessmentData;

  setAssessmentData(prevData => ({
    ...prevData,
    id,
    formTitle: name,
    headerTitle: name,
    title: name,
    fields: mapTakerFields(taker_fields),
    instructions: mapInstructions(instructions),
    completionBody: completion_body,
    completionButtonText: completion_button_text,
    completionButtonUrl: completion_button_url,
    completionHeading: completion_heading,
    showCustomCompletionPage: show_custom_completion_page,
    messageHeader: "",
    messageParagraph: "",
    logoFile: { file: null, url: logo_url }
  }));

  const expirationData = getExpirationData(
    expiration_type,
    open_date,
    close_date,
    time_to_expiration,
    expires_on
  );

  setAssessmentSettings(prevSettings => ({
    ...prevSettings,
    assessmentName: name,
    assessmentSlug: slug,
    selectedType: is_public
      ? ASSESSMENT_TYPES.PUBLIC
      : ASSESSMENT_TYPES.PRIVATE,
    emailSender: `${email_sender}`,
    emailSenderName: `${email_sender_name}`,
    email_invite_template_id,
    notificationSender: email_to_notify,
    categoriesWeight: mapCategoriesWeight(category_weights),
    ...expirationData,
    assessmentColorTheme: default_theme_color,
    isQuestionPoolEnabled: Boolean(question_pool.length),
    attemptLimit: attempt_limit,
    attemptLimitUom: attempt_limit_uom,
    webhooks: webhooks
  }));
  setFetchedWeighting(mapCategoriesWeight(category_weights));

  const questionIds = sections
    .map(({ slots }) => slots)
    .flat()
    .map(({ question_id }) => question_id)
    .filter(id => id);

  const params = new URLSearchParams();
  questionIds.forEach(id => params.append("question_ids", id));
  params.append("limit", questionIds.length);

  const questions = await getQuestionsListMW({ queryParams: params });

  setSectionsData(getSections(questions.data.paginated_data, sections));
  setIsEditing(true);
  setAssessmentId(assessmentData.id);
};
