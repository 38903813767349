// If a pathname matches the regex, it returns true
// used to hide the Navbar in the SelectedAssessments page
const shouldHideNavbar = pathname => {
  const hideNavbarPathsRegexes = [
    /\/[^/]+\/results\/[^/]+\/upload_results\/?(?:\?.*)?$/,
    /\/[^/]+\/results\/[^/]+\/mcq_results\/?(?:\?.*)?$/
  ];
  return hideNavbarPathsRegexes.some(regex => regex.test(pathname));
};

export { shouldHideNavbar };
