import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { questionTypes } from "pages/App/helpers/questionTypes";
import QuestionsTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/QuestionsTable";
import { getAssessmentAttemptMW } from "pages/PanelPage/middleware";
import { FlexContainer } from "components/Atoms/Containers";

// Component to display filtered results of a specific assessment attempt
// filtered by question type
const TakerResultsbyType = ({ type, isLoading }) => {
  const { attemptUUID } = useParams();
  const [isLoadingAttempt, setIsLoading] = useState(isLoading);
  const [filteredSections, setFilteredSections] = useState([]);
  useEffect(() => {
    // Fetchs the assessment attempt and filters the sections
    // by question type
    const fetchAssessmentAttempt = async () => {
      const takerData = await getAssessmentAttemptMW({
        urlParams: [attemptUUID]
      });

      takerData.data?.section_attempts?.forEach(section => {
        const takerfilteredSections = section.slot_attempts.filter(attempt => {
          return attempt.question_type === type;
        });
        setFilteredSections(takerfilteredSections);
      });
      setIsLoading(false);
    };
    fetchAssessmentAttempt();
  }, [attemptUUID]);
  return isLoadingAttempt ? (
    <FlexContainer data-testid={"skeleton"} direction={"column"} gap={"10"}>
      <Skeleton height={"15em"} />
      <Skeleton height={"15em"} />
      <Skeleton height={"15em"} />
    </FlexContainer>
  ) : (
    <QuestionsTable slotAttempts={filteredSections} hideAllQuestions />
  );
};
TakerResultsbyType.propTypes = {
  type: PropTypes.oneOf(Object.values(questionTypes)).isRequired,
  isLoading: PropTypes.bool
};

TakerResultsbyType.defaultProps = {
  isLoading: false
};

export default TakerResultsbyType;
